<template>
  <div id="layout" class="bg-dark text-light min-h-screen font-fancy">
    <slot />
  </div>
</template>

<style>
hr {
  background-image: url('/wave.svg');
  background-repeat: no-repeat;
  height: 11px;
  border: none; /* Remove default border */
}
</style>